[role='contentinfo'] {

  background-color: shade($dark-grey, 25%);
  color: white;

  font-size: 13.5px;
  line-height: 1.3;
  letter-spacing: 0.035em;
  font-weight: 300;

  abbr {
    text-decoration: none;
    border: 0;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover, &:focus, &:active {
      color: white;
      text-decoration: underline;
    }
  }

  h3 {
    font-weight: 100;
    font-size: 26px;
    line-height: 1;
    color: #fff;
    border-bottom: 1px solid rgba(white, .5);
    margin: 15px 0 20px 0;
  }

  address {
    font-style: normal;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    color: rgba(white, .9);
  }

  .first-row {
    position: relative;

    background-color: $dark-grey;

    padding: 30px 0 45px 0;
    min-height: 270px;

    .img-holder {

      @media (max-width: $tablet - 1) {
        display: none;
      }

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 300px;

      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
    }

    .link-wrapper {
      position: relative;
      z-index: 100;
      padding: 0 20px;

      @include tablet-settings {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @media (min-width: 720px) {
          padding: 0;
        }

        .cell:first-child {
          margin-right: 5em;
        }
      }
    }

    .cell {
      flex: 2.5;

      &[role='navigation'] {
        flex: 1;
        color: rgba(white, .685);

        @include only-smartphone {
          margin-top: 2.5em;
        }
      }
    }

    p {
      font-size: 95%;
      font-style: italic;

      a {
        text-decoration: underline;
      }
    }
  }

  .second-row {
    padding: 15px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    font-style: italic;
    letter-spacing: .035em;

    span {
      margin-right: 10px;
    }

    img {
      height: 50px;
      width: auto;
      margin-right: 10px;
    }

    a:last-child img {
      margin-right: 0;
    }

    .logo-area {
      margin-top: 15px;
      display: flex;
      flex-flow: wrap;
      justify-content: center;
    }

  }

  dl {
    margin: 1em 0 1.75em 0;
  }

  dt {
    color: rgba(white, .65);
    text-transform: lowercase;
  }

  dd {
    color: white;
    font-weight: 500;
    margin-bottom: .85em;
    margin-left: 0;

  }

}

[role='contentinfo'].home-footer {

  .first-row {
    padding: 30px;
  }

  h3 {
    border: 0;

    font-size: 62px;
    line-height: .85;
    font-weight: 100;

    margin-top: 0;
    margin-bottom: 30px;

    color: rgba(white, .85);
  }

  .link-wrapper {
    padding: 0;
  }

  [role='navigation'] {
    display: none;
  }

  @include tablet-settings {

    .first-row {
      font-size: 1.75vw;
      padding: 5vw 0 8vw 0;

      .img-holder {
        width: 23vw;
        align-items: flex-start;

        img {
          margin-top: 22.25vw;
        }
      }

      h3 {
        font-size: 10vw;
        font-weight: 100;
        line-height: .825;
        letter-spacing: 0.015em;
        margin-bottom: 4vw;
        margin-top: 3vw;

        padding-bottom: .5vw;
        border-bottom: .175vw solid rgba(white, .25);

        text-align: center;
      }

      .link-wrapper {
        max-width: 50vw;

        .cell:first-child {
          margin-right: 0;
        }
      }
    }

    .second-row {
      font-size: 1.2vw;
      p {
        margin-right: 2vw;
      }

    }

  }

}
