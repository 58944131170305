.tile.tile-link-holder.tile-servizi {
  background-color: $c2;

  @include tablet-settings {

    .spacer {
      display: inline-block;
      width: 0.125em;
    }

  }
}