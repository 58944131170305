.typography {

  color: $dark-grey;
  background-color: white;

  font-size: 16px;
  line-height: 1;

  h1, h2, h3, p {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 54px;
    line-height: 1;
    font-weight: 100;
    text-align: center;
    text-transform: lowercase;


    @include tablet-settings {
      font-size: 72px;
    }

    @include desktop-settings {
      font-size: 96px;
    }
  }

  h2 {
    position: relative;

    font-weight: 200;
    text-align: center;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: .6em;

      content: '';
      display: block;

      background-color: $dark-grey;

      z-index: 0;
    }

    span {
      position: relative;
      display: inline-block;
      min-width: 200px;
      text-align: center;

      color: rgba(white, .85);
      background-color: $dark-grey;

      padding: 5px 5px 9px 5px;
    }
  }

  h3 {
    font-weight: 600;
    margin-bottom: 10px;

  }

  &.c1 {
    h2 span { background-color: $c1; }
    h3 { color: $c1; }
  }

  &.c2 {
    h2 { margin-bottom: .5em; }
    h2 span { background-color: $c2; }
    h3 { color: lighten($c2, 10%); }
  }

  .header-spacer {
    height: 2em;
  }

  .section-spacer {
    height: 3em;
  }

  .text-container {

    color: white;
    background-color: $dark-grey;

    margin-bottom: 1.75em;
    margin-top: -8px;

    padding: 20px;
    line-height: 1.4;

    p {
      margin-bottom: 1em;
      text-align: justify;
      text-justify: inter-word;
      &:last-child { margin-bottom: 0; }
    }

    a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid;

      &:hover, &:focus {
        color: lighten($blue, 50%);
      }

      &:active {
        color: inherit;
      }
    }

  }

  .logo-list {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 2em 0 1em 0 !important;

    a {
      border: none;
      margin: 0 10px;
    }

    img {
      height: 60px;
      width: auto;
    }
  }

}
