//
// Media queries mixins
//
@mixin tablet-settings() {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop-settings() {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-lg-settings() {
  @media (min-width: $desktop-lg) {
    @content;
  }
}

@mixin desktop-xl-settings() {
  @media (min-width: $desktop-xl) {
    @content;
  }
}

@mixin only-smartphone() {
  @media (max-width: $tablet - 1) {
    @content;
  }
}
