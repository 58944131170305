.tile.tile-link-holder {
  height: 190px;
  padding: 30px;

  display: flex;
  align-items: center;

  font-size: 62px;
  line-height: .85;
  font-weight: 100;

  a {
    color: white;
    background-color: transparent;
    text-decoration: none;

    display: block;
    text-transform: lowercase;

    span {
      display: block;
    }

    .first-line {
      color: rgba(white, .5);
    }

    .second-line {
      color: rgba(white, .85);
    }

  }

  @include tablet-settings {
    height: auto;

    padding: 0;

    font-size: 10vw;
    line-height: .515;
    letter-spacing: 0.025em;

    a {
      width: 100%;
      padding: 6vw 5vw 6vw 6vw;

      .first-line {
        color: rgba(white, .35);
      }

      .second-line {
        color: rgba(white, .7);
      }

      &:hover, &:focus {
        .first-line {
          color: rgba(white, .55);
        }

        .second-line {
          color: rgba(white, 1);
        }
      }
    }

  }
}
