@mixin clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.clearfix {
  @include clearfix();
}


