html {
  box-sizing: border-box;
  font-family: $font-sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-rendering: optimizeLegibility;
}

body {
  color: black;
  background-color: white;
  overflow-y: scroll;
  min-width: 320px;
}

*, *::before, *::after {
  box-sizing: inherit;
}

i, em {
  font-style: italic;
}

b, strong {
  font-weight: 600;
}

::selection {
  color: tint($black, 10%);
  background-color: tint($orange, 80%);
}

:last-child {
  margin-bottom: 0;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.img-responsive-footer {
  filter: brightness(75%);
}

.title-line {
  height: 1em; 
  background-color:#585858; 
  margin-bottom:2em
}