.tile.tile-logo {

    position: relative;

    .selettore-lingua {
        position: absolute;
        top: -15px;
        right: 15px;
        width: 40px;

        a {
            display: block;
            margin: 0 20px 20px 0;
            font-weight: 700;

            color: tint($dark-grey, 20%);
            text-decoration: none;

            &:hover, &:focus {
                color: lighten($blue, 20%);
                text-decoration: underline;
            }

        }

    }

    @include only-smartphone {

        .logo-bankit {
            max-width: 200px;
            margin: 30px auto 10px auto;
        }

        .logo-cert, .scritta-marchio {
            max-width: 300px;
            margin: 0 auto;
        }

        .scritta-marchio {
            margin-bottom: 20px;
        }

    }

    @include tablet-settings {

        display: flex;
        flex-direction: column;
        align-content: center;

        a, img {
            display: block;
        }

        img {
            height: auto;
            margin: 0 auto;
        }

        .logo-bankit, .scritta-marchio {
            flex: 0 0 auto;
            margin: 1vw 0;
        }

        .logo-bankit img {
            max-width: 19vw;
        }

        .logo-cert img {
            max-width: 22vw;
            margin: 0;
        }

        .scritta-marchio {
            margin-bottom: 2vw;
        }

        .scritta-marchio img {
            max-width: 30vw;
        }

        .logo-cert {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .selettore-lingua {
            position: static;
            padding-top: 1vw;
            width: auto;
            display: flex;
            flex-direction: row-reverse;
        }

    }

}
