.tile-wrapper {

  margin: 0;
  padding: 0;

  @include tablet-settings {

    .tile-row {
      min-height: 350px;
      height: 50vh;

      display: flex;
      align-items: stretch;

      &.inverted {
        flex-direction: row-reverse;
      }
    }

    .tile {
      flex: 0 0 50%;
      width: 50%;
      height: auto !important;
    }
  }

}