html.enable-sticky-footer {

  height: 100%;

  body {
    display: flex;
    flex-direction: column;
    height: 100%;

    > [role='banner'],
    > [role='contentinfo'] {
      flex: none;
    }

    > [role='main'] {
      flex: 1 0 auto;
      width: 100%;
    }
  }

}