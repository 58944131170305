.plain-typography {

  margin-bottom: 60px;
  color: $dark-grey;

  h1, h2, h3 {
    color: $blue;
    line-height: 1;
  }

  h1 {
    font-size: 54px;
    font-weight: 200;
  }

  h2, h3 {
    font-weight: 400;
    margin: 1.5em 0 .35em 0;
  }

  h2 {
    font-size: 26px;
  }

  p {
    margin: 0 0 1em 0;
  }

}
