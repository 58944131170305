[role='banner'] {

  background-color: white;
  padding: 15px 15px 5px 15px;

  .bankit-logo, .cert-logo {
    margin-bottom: 10px;
  }

  .cert-logo {
    width: 230px;
  }

  .bankit-logo {
    width: 155px;
  }

  a {
    border: 0;
    text-decoration: none;
  }

  @include tablet-settings {

    .page-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      max-width: 900px;
    }

    .bankit-logo, .cert-logo {
      margin: 0;
    }

    .bankit-logo {
      width: 210px;
    }

    .cert-logo {
        width: 260px;
    }

  }

}
